import React from "react";
import "./contact-us-page.scss";
import { HeaderWidget } from "../../widgets/header/header-widget";
import { FooterWidget } from "../../widgets/footer/footer-widget";
import { BasicSectionWidget } from "../../widgets/basic-section/basic-section-widget";
import { Card, Row } from "react-bootstrap";
import { SiGmail, SiLinkedin, SiWhatsapp } from "react-icons/si";

export default class ContactUsPage extends React.Component {
  render() {
    return (
      <div className="contact-us-page bg-dark">
        <HeaderWidget />
        <div className="content w-50 mx-auto pt-3">
          <BasicSectionWidget title={"📲 Contate-nos"} />

          <h5 className="text-light mt-3">
            Para entrar em contato conosco, você pode utilizar o Whatsapp, Email
            ou Linkedin. Escolha o método mais adequado em seu caso que
            estaremos de prontidão para lhe ajudar.
          </h5>

          <p className="text-center text-light">(Atendimento das 8h - 22h)</p>

          <Row
            xs={1}
            md={2}
            xl={3}
            className="row-cards d-flex justify-content-evenly mt-5 text-light text-center"
          >
            <Card className="bg-transparent">
              <Card.Body className="px-0">
                <Card.Title>Gmail</Card.Title>
                <SiGmail className="text-danger w-25 h-25" />

                <Card.Text>
                  Em caso de problemas técnicos com a plataforma ou em busca de
                  contratar a Dibba entre em contato pelo email: <br />
                  contatodibba@gmail.com
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Card.Link
                  className="text-light"
                  href="mailto:contatodibba@gmail.com"
                >
                  Enviar Email
                </Card.Link>
              </Card.Footer>
            </Card>

            <Card className="bg-transparent">
              <Card.Body className="px-0">
                <Card.Title>WhatsApp</Card.Title>
                <SiWhatsapp className="text-success w-25 h-25" />

                <Card.Text>
                  Em caso de problemas com a plataforma, pagamento ou ingresso
                  entre em contato por de nossos canais no Whatsapp: <br /> (48)
                  98426-2064 ou (48) 99850-0989
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Card.Link
                  className="text-light"
                  href="https://web.whatsapp.com/send?phone=5548984262064"
                  target="_blank"
                >
                  Rep. 1
                </Card.Link>
                <Card.Link
                  className="text-light"
                  href="https://web.whatsapp.com/send?phone=5548998500989"
                  target="_blank"
                >
                  Rep. 2
                </Card.Link>
              </Card.Footer>
            </Card>

            <Card className="bg-transparent">
              <Card.Body className="px-0">
                <Card.Title>LinkedIn</Card.Title>
                <SiLinkedin className="text-primary w-25 h-25" />

                <Card.Text>
                  Em busca de contratar os serviços da Dibba entre em contato
                  pelo nosso LinkedIn.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Card.Link
                  className="text-light"
                  href="https://www.linkedin.com/company/dibbabr/"
                  target="_blank"
                >
                  Ir para o LinkedIn
                </Card.Link>
              </Card.Footer>
            </Card>
          </Row>
        </div>
        <FooterWidget />
      </div>
    );
  }
}
