import React from "react";
import { Card, Form, FloatingLabel, Button } from "react-bootstrap";
import axios from "axios";
import EventService from "../../../services/event-service";
import { FooterWidget } from "../../widgets/footer/footer-widget";
import { HeaderWidget } from "../../widgets/header/header-widget";
import { AiFillCreditCard } from "react-icons/ai";
import { MdPix } from "react-icons/md";
import { RiBillLine } from "react-icons/ri";
import "./payment-page.scss";

/**
 * Migrar o doPostBuy para o próprio método eventService.buy()
 * Componentizar os elementos quando for boleto, pix ou CREDIT_CARD
 * [Ver se existe] Adicionar dimmer quando estiver no isLoading
 *
 * [BUG] Pode ser selecionado o metodo de pagamento sem selecionar um evento
 */

export default class PaymentPage extends React.Component {
  constructor() {
    super();
    this.state = {
      eventsToBuy: [],
      eventSelectioned: "",
      isLoading: true,
      isLoadingBuy: false,
      config: sessionStorage.getItem("tk"),
      paymentMethod: "",
      zipCode: "",
      state: "",
      city: "",
      district: "",
      address: "",
      addressNumber: "",
      addressComplement: "",
      cvv: "",
      cardNumber: "",
      cardHolderName: "",
      expirationYear: "",
      expirationMonth: "",
      installmentsQuantity: 0,
      halfTicket: false,
      quantity: 0,
      response: "",
    };
  }

  componentDidMount() {
    let eventsToBuyJson = sessionStorage.getItem("eventsToBuy");
    let eventsToBuyArray = JSON.parse(eventsToBuyJson);

    this.setState({
      ...this.state,
      eventsToBuy: eventsToBuyArray,
      isLoading: false,
    });
  }

  async buyEvent(eventId, type, spaceOrCabinId) {
    this.setState({
      ...this.state,
      isLoadingBuy: true,
    });
    let eventService = new EventService();
    let body = {};

    if (this.state.paymentMethod === "CREDIT_CARD") {
      body = {
        paymentMethod: "CREDIT_CARD",
        paymentData: {
          cvv: this.state.cvv,
          city: this.state.city,
          address: this.state.address,
          zipCode: this.state.zipCode,
          district: this.state.district,
          cardNumber: this.state.cardNumber,
          addressNumber: this.state.addressNumber,
          cardHolderName: this.state.cardHolderName,
          expirationYear: this.state.expirationYear,
          expirationMonth: this.state.expirationMonth,
          addressComplement: this.state.addressComplement,
          state: this.state.state,
          installmentsQuantity: 1,
        },
        /* typeForSex: this.state.eventSelectioned.typeLocalTicket, */
        sex: "U",
        halfTicket: false,
        quantity: 1,
      };
    } else if (this.state.paymentMethod === "PIX") {
      body = {
        paymentMethod: "PIX",
        paymentData: {
          city: this.state.city,
          address: this.state.address,
          zipCode: this.state.zipCode,
          district: this.state.district,
          addressNumber: this.state.addressNumber,
          addressComplement: this.state.addressComplement,
          state: this.state.state,
        },
        sex: "U",
        halfTicket: false,
        quantity: this.state.eventSelectioned.quantity,
      };
    } else if (this.state.paymentMethod === "BOLETO") {
      this.state.eventSelectioned.typeLocalTicket === "spaces"
        ? (body = {
            paymentMethod: "BOLETO",
            paymentData: {
              city: this.state.city,
              address: this.state.address,
              zipCode: this.state.zipCode,
              district: this.state.district,
              addressNumber: this.state.addressNumber,
              addressComplement: this.state.addressComplement,
              state: this.state.state,
            },
            sex: "U",
            halfTicket: false,
            quantity: 1,
          })
        : (body = {
            paymentMethod: "BOLETO",
            paymentData: {
              city: this.state.city,
              address: this.state.address,
              zipCode: this.state.zipCode,
              district: this.state.district,
              addressNumber: this.state.addressNumber,
              addressComplement: this.state.addressComplement,
              state: this.state.state,
            },
            cabinEmailAddresses: this.state.eventSelectioned.emails,
          });
    }

    const res = await eventService.buy(
      eventId,
      type,
      spaceOrCabinId,
      body,
      this.state.config
    );

    this.setState({
      ...this.state,
      response: res.paymentResponse,
      isLoadingBuy: false,
    });
  }

  getCEP(cepInputValue) {
    // https://h-apigateway.conectagov.estaleiro.serpro.gov.br/api-cep/v1/consulta/cep/60130240
    if (cepInputValue.length === 8) {
      cepInputValue = cepInputValue.replace("-", "");
      axios
        .get(`https://viacep.com.br/ws/${cepInputValue}/json/`)
        .then((res) => {
          console.log(res)
          this.setState({
            ...this.state,
            zipCode: cepInputValue,
            state: res.data.uf,
            city: res.data.localidade,
            district: res.data.bairro,
            address: res.data.logradouro,
          })
        });
    }
  }

  AddressComponent() {
    return (
      <Form>
        <Form.Text className="mt-2">Endereço de cobrança</Form.Text>
        <FloatingLabel
          controlId="floatingInput"
          label="CEP *"
          className="text-dark"
        >
          <Form.Control
            type="text"
            placeholder="CEP *"
            className="mt-2"
            onChange={(e) => {
              this.setState({
                ...this.state,
                zipCode: e.target.value,
              });
              this.getCEP(e.target.value);
            }}
          />
        </FloatingLabel>

        <Form.Group className="mt-2 d-flex gap-2">
          <FloatingLabel
            controlId="floatingInput"
            label="Estado *"
            className="text-dark w-100"
          >
            <Form.Control
              type="text"
              defaultValue={this.state.city}
              placeholder="Estado *"
              disabled
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Cidade *"
            className="text-dark w-100"
          >
            <Form.Control
              type="text"
              placeholder="Cidade *"
              value={this.state.city}
              disabled
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="mt-2 d-flex gap-2">
          <FloatingLabel
            controlId="floatingInput"
            label="Bairro *"
            className="text-dark w-75"
          >
            <Form.Control
              type="text"
              placeholder="Bairro *"
              value={this.state.district}
              disabled
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Logradouro (Rua) *"
            className="text-dark w-100"
          >
            <Form.Control
              type="text"
              placeholder="Logradouro (Rua) *"
              value={this.state.address}
              disabled
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="mt-2 d-flex gap-2">
          <FloatingLabel
            controlId="floatingInput"
            label="Número *"
            className="text-dark w-25"
          >
            <Form.Control
              type="number"
              placeholder="Número *"
              min={1}
              onChange={(e) =>
                this.setState({
                  ...this.state,
                  addressNumber: e.target.value,
                })
              }
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Complemento"
            className="text-dark w-100"
          >
            <Form.Control
              type="text"
              placeholder="Complemento"
              onChange={(e) =>
                this.setState({
                  ...this.state,
                  addressComplement: e.target.value,
                })
              }
            />
          </FloatingLabel>
        </Form.Group>
      </Form>
    );
  }

  render() {
    return (
      <div className="payment-page bg-dark text-light">
        <HeaderWidget />
        <div className="payment-form w-50 mx-auto">
          <h1 className="text-center my-5">Página de Pagamento</h1>

          {this.state.isLoading && (
            <div className="text-light text-center">
              <p>Carregando eventos...</p>
            </div>
          )}

          {!this.state.isLoading && !this.state.eventsToBuy ? (
            <h5 className="text-light">
              Você não tem nenhum evento no carrinho
            </h5>
          ) : (
            <div>
              <h5 className="text-center">
                Selecione o evento ao qual deseja realizar o pagamento.
              </h5>
              <div className="d-flex row-cards">
                {this.state.eventsToBuy.map((event, i) => (
                  <Card
                    className="bg-dark my-2 text-light"
                    onClick={() => {
                      this.setState({ ...this.state, eventSelectioned: event });
                    }}
                    border={
                      this.state.eventSelectioned.id === event.id
                        ? "primary"
                        : ""
                    }
                  >
                    <Card.Body>
                      <h4>{event.name}</h4>
                      <small className="text-secondary">
                        {event.description}
                      </small>
                    </Card.Body>
                  </Card>
                ))}
              </div>

              {this.state.eventSelectioned && (
                <>
                  <h5 className="mt-3 text-center">
                    Pague o evento das seguintes formas:
                  </h5>
                  <div className="mt-4 cards-methods">
                    <Card
                      className="text-light bg-dark text-center"
                      border={
                        this.state.paymentMethod === "CREDIT_CARD"
                          ? "primary"
                          : ""
                      }
                      style={{ width: "18rem" }}
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          paymentMethod: "CREDIT_CARD",
                        });
                      }}
                    >
                      <Card.Header>
                        <AiFillCreditCard size={40} />
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>Cartão de Credito</Card.Title>
                        <Card.Text>
                          Pague a vista com seu cartão de crédito
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      className="text-light bg-dark text-center"
                      border={
                        this.state.paymentMethod === "PIX" ? "primary" : ""
                      }
                      style={{ width: "18rem" }}
                      onClick={() => {
                        this.setState({ ...this.state, paymentMethod: "PIX" });
                      }}
                    >
                      <Card.Header>
                        <MdPix size={40} />
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>Pix</Card.Title>
                        <Card.Text>Pague a vista no Pix</Card.Text>
                      </Card.Body>
                    </Card>
                    <Card
                      className="text-light bg-dark text-center"
                      border={
                        this.state.paymentMethod === "BOLETO" ? "primary" : ""
                      }
                      style={{ width: "18rem" }}
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          paymentMethod: "BOLETO",
                        });
                      }}
                    >
                      <Card.Header>
                        <RiBillLine size={40} />
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>Boleto</Card.Title>
                        <Card.Text>Pague a vista com boleto</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </>
              )}

              {this.state.paymentMethod === "CREDIT_CARD" && (
                <div className="m-3">
                  Pagamento no cartão
                  <Form.Text className="mt-2">Informações do cartão</Form.Text>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nome no Cartão *"
                    className="text-dark"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Nome no Cartão *"
                      className="mt-2"
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          cardHolderName: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Número do Cartão *"
                    className="text-dark"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Número do Cartão *"
                      className="mt-2"
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          cardNumber: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                  <Form.Group className="mt-2 d-flex gap-2 w-100">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Mês de expiração *"
                      className="text-dark w-100"
                    >
                      <Form.Select
                        aria-label="Mês de expiração *"
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            expirationMonth: e.target.value,
                          })
                        }
                      >
                        <option value="">Selecione o mês</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Ano de expiração *"
                      className="text-dark w-100"
                    >
                      <Form.Select
                        aria-label="Ano de expiração *"
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            expirationYear: e.target.value,
                          })
                        }
                      >
                        <option value="">Selecione o ano</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                        <option value="2028">2028</option>
                        <option value="2029">2029</option>
                        <option value="2030">2030</option>
                        <option value="2031">2031</option>
                        <option value="2032">2032</option>
                        <option value="2033">2033</option>
                        <option value="2034">2034</option>
                        <option value="2035">2035</option>
                      </Form.Select>
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="CVV *"
                      className="text-dark w-100"
                    >
                      <Form.Control
                        type="text"
                        placeholder="CVV *"
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            cvv: e.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Parcelas *" 
                    className="text-dark"
                  >
                    <Form.Select
                      aria-label="Parcelas *" 
                      className="mt-2"
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          installmentsQuantity: e.target.value,
                        })
                      }
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                    </Form.Select>
                  </FloatingLabel>
                  {this.AddressComponent()}
                  <Button
                    className="d-block mt-3"
                    onClick={() => {
                      this.buyEvent(
                        this.state.eventSelectioned.id,
                        this.state.eventSelectioned.typeLocalTicket,
                        this.state.eventSelectioned.idLocalTicket
                      );
                    }}
                  >
                    Clique aqui para realizar o pagamento
                  </Button>
                  {this.state.isLoadingBuy && (
                    <div className="mt-3">
                      <h2 className="text-light my-3">Carregando compra...</h2>
                    </div>
                  )}
                  {this.state.response && (
                    <div>
                      <h2 className="text-light">
                        {this.state.response.responseDetail.description}
                      </h2>
                      <p>{this.state.response.responseDetail.description}</p>
                    </div>
                  )}
                </div>
              )}

              {this.state.paymentMethod === "PIX" && (
                <div className="m-3">
                  Pagamento no pix
                  {this.AddressComponent()}
                  <Button
                    className="d-block mx-auto mt-4"
                    type="submit"
                    onClick={() => {
                      this.buyEvent(
                        this.state.eventSelectioned.id,
                        this.state.eventSelectioned.typeLocalTicket,
                        this.state.eventSelectioned.idLocalTicket
                      );
                    }}
                  >
                    Clique aqui para gerar o QR Code
                  </Button>
                  {this.state.isLoadingBuy && (
                    <div className="mt-3">
                      <h2 className="text-light my-3">Carregando QR Code...</h2>
                    </div>
                  )}
                  {this.state.response && (
                    <div>
                      <h2 className="text-light">QR Code para pagamento: </h2>
                      <img
                        src={this.state.response.responseDetail.QRCode}
                        alt="Imagem do QR Code"
                      />
                      <p>
                        Código do QR Code:
                        {this.state.response.responseDetail.Key}
                      </p>
                      <p>{this.state.response.responseDetail.Description}</p>
                      <p>
                        Status: {this.state.response.responseDetail.Message}
                      </p>
                    </div>
                  )}
                </div>
              )}

              {this.state.paymentMethod === "BOLETO" && (
                <div className="m-3">
                  Pagamento no boleto
                  {this.AddressComponent()}
                  <Button
                    className="d-block mt-4 mx-auto"
                    type="submit"
                    onClick={() => {
                      this.buyEvent(
                        this.state.eventSelectioned.id,
                        this.state.eventSelectioned.typeLocalTicket,
                        this.state.eventSelectioned.idLocalTicket
                      );
                    }}
                  >
                    Clique aqui para gerar o boleto
                  </Button>
                  {this.state.isLoadingBuy && (
                    <div className="mt-3">
                      <h2 className="text-light my-3">Carregando boleto...</h2>
                    </div>
                  )}
                  {this.state.response && (
                    <div className="mt-3">
                      <h2 className="text-light my-3">
                        Boleto para pagamento:{" "}
                      </h2>

                      <div
                        onClick={() =>
                          window.open(
                            `${this.state.response.responseDetail.BankSlipUrl}`,
                            "_blank"
                          )
                        }
                      >
                        <iframe
                          title="policy-and-terms"
                          src={`${this.state.response.responseDetail.BankSlipUrl}`}
                          style={{
                            minWidth: "43vw",
                            height: "40vh",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <FooterWidget />
      </div>
    );
  }
}
