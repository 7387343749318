import "./modal-terms-widget.scss";

export const ModalTermsWidget = ({ isOpen, closeModal, modalItem }) => {
  if (isOpen) {
    return (
      <div className="modal-background" onClick={() => closeModal()}>
        <div className="modal-container">
          <div className="responsive-container">
            <iframe
              title="policy-and-terms"
              src={`${modalItem}/preview`}
              style={{ minWidth: '43vw', height: "90vh" }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
