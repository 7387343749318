import React from "react";
import "./event-page.scss";
import { HeaderWidget } from "../../widgets/header/header-widget";
import { FooterWidget } from "../../widgets/footer/footer-widget";
import { EventDetailedWidget } from "../../widgets/event-detailed/event-detailed-widget";

export default class EventPage extends React.Component {
  render() {
    return (
      <div className="event-page bg-dark w-100">
        <HeaderWidget />
        <EventDetailedWidget />
        <FooterWidget />
      </div>
    );
  }
}
