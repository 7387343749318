import { Form, FloatingLabel, Row, Col } from "react-bootstrap";
import { useState } from "react";
import "./profile-form-widget.scss";

export const ProfileFormWidget = ({ user }) => {
  const [userInfos, setUserInfos] = useState(user);

  return (
    <div className="profile-form w-75">
      <div className="title">
        <h4>Seus dados</h4>
      </div>

      <Row xs={1} md={2}>
        <Col>
          <FloatingLabel
            controlId="floatingInput"
            label="Nome"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Nome"
              className="form-input"
              disabled
              value={userInfos.firstName}
              onChange={({ target }) => {
                setUserInfos({
                  ...userInfos,
                  firstName: target.value,
                });
              }}
            />
          </FloatingLabel>
        </Col>

        <Col>
          <FloatingLabel
            controlId="floatingInput"
            label="Sobrenome"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Sobrenome"
              className="form-input"
              disabled
              value={userInfos.lastName}
              onChange={({ target }) => {
                setUserInfos({
                  ...userInfos,
                  lastName: target.value,
                });
              }}
            />
          </FloatingLabel>
        </Col>

        <Col>
          <FloatingLabel
            controlId="floatingInput"
            label="CPF"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="CPF"
              className="form-input"
              disabled
              value={userInfos.docNumber}
              onChange={({ target }) => {
                setUserInfos({
                  ...userInfos,
                  docNumber: target.value,
                });
              }}
            />
          </FloatingLabel>
        </Col>

        <Col>
          <FloatingLabel
            controlId="floatingInput"
            label="Email"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Email"
              className="form-input"
              disabled
              value={userInfos.emailAddress}
              onChange={({ target }) => {
                setUserInfos({
                  ...userInfos,
                  emailAddress: target.value,
                });
              }}
            />
          </FloatingLabel>
        </Col>

        <Col>
          <FloatingLabel
            controlId="floatingInput"
            label="Telefone"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Telefone"
              className="form-input"
              disabled
              value={userInfos.phone}
              onChange={({ target }) => {
                setUserInfos({
                  ...userInfos,
                  phone: target.value,
                });
              }}
            />
          </FloatingLabel>
        </Col>
      </Row>
    </div>
  );
};
