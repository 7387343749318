import "./footer-widget.scss";
import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ModalTermsWidget } from "../modal-terms/modal-terms-widget";

export const FooterWidget = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalItem, setModalItem] = useState("");

  return (
    <footer className="pt-5 bg-dark">
      <hr />

      <Row lg={3} xs={1} className="footer-widget text-light w-100 m-0 d-flex justify-content-center align-items-start pt-3 pb-4">
        <Col className="d-flex flex-column align-items-center justify-content-center">
          <ul>
            <h5>Institucional</h5>

            <li onClick={() => {
              setModalItem("https://drive.google.com/file/d/1eWHRD5pFJu_6I2M8407FbzAG2KkJlak3")
              setModalIsOpen(true)
            }}>
              <a
                href="#politica-de-dados"
                rel="noreferrer"
              >
                Política de dados
              </a>
            </li>
            <li onClick={() => {
              setModalItem("https://drive.google.com/file/d/11r4Uv1JCdm2M9rVLs3d44glJ8PB-_Hr9")
              setModalIsOpen(true)
            }}>
              <a href="#termos-e-condicoes">Termos e condições de uso</a>
            </li>
            <li>
              <a
                href="https://form.jotform.com/223583463072658"
                rel="noreferrer"
                target="_blank"
              >
                Seja um representante DIBBA
              </a>
            </li>
          </ul>
        </Col>

        <Col className="d-flex flex-column align-items-center justify-content-center">
          <ul>
            <h5>Atendimento</h5>

            <li>
              <a href="/help">Ajuda</a>
            </li>
            <li>
              <a href="/contact-us">Contate-nos</a>
            </li>
            <li>
              <a href="mailto:contatodibba@gmail.com" rel="noreferrer">
                contatodibba@gmail.com
              </a>
            </li>
          </ul>
        </Col>

        <Col className="d-flex flex-column align-items-center justify-content-center">
          <ul>
            <h5>Redes Sociais</h5>

            <li>
              <a
                href="https://www.linkedin.com/company/dibbabr/"
                target="_blank"
                rel="noreferrer"
              >
                Linkedin
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/dibbabr/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@dibbabr"
                target="_blank"
                rel="noreferrer"
              >
                TikTok
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/dibbabr"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      
      <ModalTermsWidget
        isOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        modalItem={modalItem}
      />
    </footer>
  );
};
