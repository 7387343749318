import "./cart-card-widget.scss"

export const CartCardWidget = (props) => {
  function dropEvent() {
    let arrayEvents = JSON.parse(sessionStorage.getItem("eventsToBuy"))
    const event = props.eventObject
    let itemPosition = arrayEvents.findIndex(e => e.id === event.id);
    arrayEvents.splice(itemPosition, 1)
    sessionStorage.setItem("eventsToBuy", JSON.stringify(arrayEvents))
    window.location.reload()
  }

  console.log(props.eventObject)
  // Setar preço quando enviar enviar ao carrinho

  return (
    <section className="cart-card p-3 mt-3 text-light">
      <aside className="image">
        <img src={props.eventObject.bannerImage} alt="Banner do Evento" className="w-100 h-100" />
      </aside>

      <aside className="event-informations">
        <header className="d-flex pb-2">
          <h5>{props.eventObject.name}</h5>
        </header>
        <p className="fw-lighter text-light text-justify">{props.eventObject.description}</p>
        <p className="fw-lighter text-light text-justify">R$ {props.eventObject.price.toFixed(2)}</p>
      </aside>

      <aside className="close-button">
        <button className="text-light fw-bold" onClick={() => { dropEvent() }}>X</button>
      </aside>
    </section>
  );
};
