import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import './recover-password-form-widget.scss'
import logoDibba from '../../../assets/images/logo_login.png'
import AuthService from '../../../services/auth-service';
import NotificationService from "../../../services/notification-service";
import { NOTIFICATION_MSG_TYPE } from "../../../common/variables";

export const RecoverPasswordWidget = props => {

    const authenticationService = new AuthService();
    const notificationService = new NotificationService();

    const [email, setEmail] = useState("");

    function handleSubmit() {
        let containError = false;

        if (email === "") {
            containError = true;
        } else {
            authenticationService.recover(email);
            notificationService.consume(
                NOTIFICATION_MSG_TYPE.SUCCESS,
                "Verifique o email enviado para sua caixa de entrada"
                );
        }

        if (containError) {
            notificationService.consume(
                NOTIFICATION_MSG_TYPE.ERROR,
                "Um dos campos está vazio"
            );
        }
    }

    return (
        <Form className="recover-password-form">
            <div className="logo mb-4">
                <img src={logoDibba} alt="Logo Dibba" />
            </div>

            <Form.Control className="form-input mb-3" type="email" placeholder="Insira seu email" onChange={(e) => setEmail(e.target.value)} />

            <Button className="mt-3 recover" onClick={() => { handleSubmit() }}>
                Recuperar senha
            </Button>
        </Form>
    )
}