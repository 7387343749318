import "./event-card-widget.scss";
import { Row, Col, Image, Button } from "react-bootstrap";
import formatDate from "../../../utils/formatDate";
import formatTime from "../../../utils/formatTime";

export const EventCardWidget = (props) => {
  const event = props.event;

  const infos = [
    {
      title: "Local",
      content: event.addressDescription,
    },
    {
      title: "Data",
      content: `${formatDate(event.startDateTime)}
      ${
        formatDate(event.endDateTime) !== formatDate(event.startDateTime)
          ? `- ${formatDate(event.endDateTime)}`
          : ""
      }`,
    },
    {
      title: "Horário",
      content: `${formatTime(event.startDateTime)} - ${formatTime(
        event.endDateTime
      )}`,
    },
    {
      title: "Idade Mínima",
      content: event.ageClassification,
    },
  ];

  return (
    <div className="event-card-widget bg-darker">
      <div className="head p-4">
        <Image src={event.bannerImage} className="w-50" />
        <h5 className="my-4 text-white">{event.name}</h5>
        <div className="infos text-light">
          <div className="list">
            <Row xs={1} sm={2}>
              {infos.map((info, i) => (
                <Col key={i}>
                  <div className="info-row">
                    <small>{info.title}</small>
                    <p>{info.content}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
      <Button
        className="w-100 text-center text-light fw-light details-button"
        onClick={() => {
          window.location.assign(`/event/${event.id}`);
        }}
      >
        Detalhes
      </Button>
    </div>
  );
};
