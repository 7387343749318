import React from "react";
import "./cart-page.scss";
import { BasicSectionWidget } from "../../widgets/basic-section/basic-section-widget";
import { CartCardWidget } from "../../widgets/cart-card/cart-card-widget";
import { HeaderWidget } from "../../widgets/header/header-widget";
import { FooterWidget } from "../../widgets/footer/footer-widget";

import { Button } from "react-bootstrap";
import NotificationService from "../../../services/notification-service";

export default class CartPage extends React.Component {
  constructor() {
    super();
    this.state = {
      notificationService: new NotificationService(),
      eventsToBuy: [],
      totalValue: 0
    };
  }

  componentDidMount() {
    const eventsToBuyArray = JSON.parse(sessionStorage.getItem("eventsToBuy"))
    const arrayValues = eventsToBuyArray > 0 ? eventsToBuyArray.map((event) => event.quantity * event.price) : eventsToBuyArray[0].price
    this.setState({
      ...this.state,
      eventsToBuy: eventsToBuyArray,
      totalValue: eventsToBuyArray > 0 ? arrayValues.reduce(function(acumulador, valorAtual) {
        return acumulador + valorAtual;
      }) : arrayValues
    });

    return;
  }

  render() {
    return (
      <div className="cart-page bg-dark text-light">
        <HeaderWidget />
        <div className="cart-content w-50 mx-auto">
          <h1 className="text-center my-5">Seu Carrinho</h1>

          <BasicSectionWidget title={"Veja suas compras aqui."} />

          {!sessionStorage.hasOwnProperty("user") ? (
            <div className="mt-5 text-light text-center fw-light">
              <p>
                Você precisa{" "}
                <a href="/sign-in" className="text-light fw-normal">
                  fazer login
                </a>{" "}
                para ver seu carrinho
              </p>
            </div>
          ) : (
            <div>
              {!this.state.isLoading && !this.state.eventsToBuy ? (
                <h5 className="text-light">Você não tem nenhum evento</h5>
              ) : (
                <div>
                  {this.state.eventsToBuy.map((row, i) => (
                    <CartCardWidget
                      eventObject={row}
                    />
                  ))}

                  <hr />
                  <h3 className="text-right">Valor total: R${this.state.totalValue.toFixed(2)}</h3>
                  <div className="buy-button my-3 w-100 text-right">
                    <Button
                      onClick={() => { window.location.assign("/payment") }} 
                    >
                      Finalizar Compra
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <FooterWidget />
      </div>
    );
  }
}
