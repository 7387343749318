import React from "react";
import "./help-page.scss";
import { Accordion } from "react-bootstrap";
import { HeaderWidget } from "../../widgets/header/header-widget";
import { FooterWidget } from "../../widgets/footer/footer-widget";
import { BasicSectionWidget } from "../../widgets/basic-section/basic-section-widget";

export default class HelpPage extends React.Component {
  render() {
    return (
      <div className="help-page bg-dark text-light">
        <HeaderWidget />
        <div className="content w-50 mx-auto">
          <BasicSectionWidget title={"🔍 Ajuda"} />
          
          <h5 className="text-light mt-3">Perguntas Frequentes:</h5>

          <Accordion eventKey="0" alwaysOpen className="mt-4 bg-dark">
            <Accordion.Item className="bg-darkness text-light" eventKey="0">
              <Accordion.Header>Métodos de Pagamento?</Accordion.Header>
              <Accordion.Body>
                Aceitamos pagamentos, pelo PIX, Boleto Bancário, e pelos
                seguintes Cartões de Crédito: Visa, Mastercard, American
                Express, Elo, Diners Club, Discover e Hipercard.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="bg-darkness text-light" eventKey="1">
              <Accordion.Header>Onde recebo meu Ingresso?</Accordion.Header>
              <Accordion.Body>
                Você receberá o seu ingresso pelo email, e ele também estará
                disponível no app na Dibba na aba Meus Tickets.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="bg-darkness text-light" eventKey="2">
              <Accordion.Header>
                Consigo Transferir o Meu Ingresso?
              </Accordion.Header>
              <Accordion.Body>
                Por enquanto a transferência de ingressos ainda não é possível,
                mas em futuras atualização ela estará disponível.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="bg-darkness text-light" eventKey="3">
              <Accordion.Header>
                Como funciona a compra do ingresso em grupo (camarotes)?
              </Accordion.Header>
              <Accordion.Body>
                Nesta modalidade de ingresso, você receberá todos os ingressos
                por email para serem reencaminhados para os outras pessoas que
                irão com você, junto do email estarão instruções para a entrada
                no dia do evento.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="bg-darkness text-light" eventKey="4">
              <Accordion.Header>
                Como entro em contato com a Dibba?
              </Accordion.Header>
              <Accordion.Body>
                Caso a sua dúvida não tenha sido respondida você pode entrar em
                contato com a Dibba na página de Contato. LINK
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <FooterWidget />
      </div>
    );
  }
}
