export const BasicSectionWidget = (props) => {
  return (
    <section className="text-light">
      <h2>{props.title}</h2>
      <hr />
      <p className="fw-lighter text-justify">{props.firstText}</p>
      <p className="fw-lighter text-justify">{props.secondText}</p>
    </section>
  );
};
