import { useState } from 'react';
import { useParams } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import './new-password-form-widget.scss'
import logoDibba from '../../../assets/images/logo_login.png'
import parseBase64 from '../../../utils/parseBase64';
import AuthService from '../../../services/auth-service';
import NotificationService from '../../../services/notification-service';
import { NOTIFICATION_MSG_TYPE } from '../../../common/variables';

export const NewPasswordWidget = props => {

    const recoverService = new AuthService()
    const notificationService = new NotificationService()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const key = useParams();
    const finishKey = key.key;

    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordType, setShowPasswordType] = useState("password")
    const [confirmShowPassword, setConfirmShowPassword] = useState(false)
    const [confirmShowPasswordType, setConfirmShowPasswordType] = useState("password")

    function handleNewPassword() {
        let containError = false;
        const newPasswordDTO = [email, password, finishKey]

        newPasswordDTO.forEach((item, i) => {
            if (item.trim() === "") {
                containError = true;
            } else {
                recoverService.newPassword(password, email, finishKey);
            }
        });

        if (containError) {
            notificationService.consume(
                NOTIFICATION_MSG_TYPE.ERROR,
                "Um dos campos está vazio"
            );
        }
    }

    function handleShowPassword() {
        if (showPassword === false) {
            setShowPassword(true)
            setShowPasswordType("text")
        } else {
            setShowPassword(false)
            setShowPasswordType("password")
        }
    }

    function handleShowConfirmPassword() {
        if (confirmShowPassword === false) {
            setConfirmShowPassword(true)
            setConfirmShowPasswordType("text")
        } else {
            setConfirmShowPassword(false)
            setConfirmShowPasswordType("password")
        }
    }

    return (
        <Form className="new-password-form">
            <div className="logo mb-5">
                <img src={logoDibba} alt="Logo Dibba" />
            </div>

            <div className="eye-password"><i className="fi fi-rs-eye" onClick={() => { handleShowPassword() }}></i></div>
            <Form.Control className="form-input mb-3" type={showPasswordType} placeholder="Sua nova senha" onChange={(e) => setPassword(e.target.value)} onClick={() => { setEmail(parseBase64(key.key)) }} />
            <div className="eye-password"><i className="fi fi-rs-eye" onClick={() => { handleShowConfirmPassword() }}></i></div>
            <Form.Control className="form-input mb-3" type={confirmShowPasswordType} placeholder="Confirme sua nova senha" />

            <Button className="mt-3 new" onClick={() => { handleNewPassword() }}>
                Criar nova senha
            </Button>
        </Form>
    )
}