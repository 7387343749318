import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Accordion, Button } from "react-bootstrap";
import { BiMap } from "react-icons/bi";
import { IoAddCircle } from "react-icons/io5";
import { IoMdRemoveCircle } from "react-icons/io";
import { HiUserGroup } from "react-icons/hi";
import { MdDateRange } from "react-icons/md";
import NotificationService from "../../../services/notification-service";
import { NOTIFICATION_MSG_TYPE } from "../../../common/variables";
import EventService from "../../../services/event-service";
import formatDate from "../../../utils/formatDate";
import formatTime from "../../../utils/formatTime";
import "./event-detailed-widget.scss";

export const EventDetailedWidget = () => {
  const eventId = useParams();
  const [event, setEvent] = useState();
  const [quantity, setQuantity] = useState(0);
  const [hasBuys, setHasBuys] = useState(
    sessionStorage.getItem("eventsToBuy")
      ? sessionStorage.getItem("eventsToBuy").length > 0
      : ""
  );
  const notificationService = new NotificationService();
  // const [emails, setEmails] = useState([]);
  // const [emailQuantity, setEmailQuantity] = useState();

  useEffect(() => {
    setHasBuys(
      sessionStorage.getItem("eventsToBuy")
        ? JSON.parse(sessionStorage.getItem("eventsToBuy")).length > 0
        : ""
    );
  }, [quantity, hasBuys]);

  useEffect(() => {
    const service = new EventService();
    const res = service.getById(eventId.id);
    res.then((data) => {
      setEvent(data);
    });
  }, [eventId.id]);

  useEffect(() => {
    const arrayEvents = JSON.parse(sessionStorage.getItem("eventsToBuy"));
    const found = arrayEvents
      ? arrayEvents.find((eventFind) => eventFind.id === eventId.id)
      : "";
    if (found) {
      setQuantity(found.quantity);
    }
  }, [quantity, eventId.id]);

  function eventToCart(localId, spaceOrCabin, price, gender = "") {
    console.log(price)
    if (sessionStorage.hasOwnProperty("user")) {
      let arrayEvents = [];
      const eventInfos = event
      eventInfos.typeLocalTicket = spaceOrCabin
      eventInfos.idLocalTicket = localId
      if(!eventInfos.price){
        eventInfos.price = price
      }

      if (gender === "FEMALE") {
        eventInfos.femaleTickets += 1;
      } else if (gender === "MALE") {
        eventInfos.maleTickets += 1;
      }
      // let emailArrays = emails && emails.split(", ");

      switch (spaceOrCabin) {
        case "spaces":
          eventInfos.quantity = eventInfos.quantity
            ? eventInfos.quantity + 1
            : 1;
          break;
        case "cabins":
          console.log("Cabins");
          // eventInfos.emails = emailArrays;
          break;
        default:
          console.log("default");
          break;
      }

      if (sessionStorage.hasOwnProperty("eventsToBuy")) {
        arrayEvents = JSON.parse(sessionStorage.getItem("eventsToBuy"));
      }

      const found = arrayEvents.find((event) => event.id === eventInfos.id);

      if (!found) {
        notificationService.consume(
          NOTIFICATION_MSG_TYPE.SUCCESS,
          "O evento foi adicionado ao seu carrinho. Acesse o carrinho para finalizar sua compra"
        );
        arrayEvents.push(eventInfos);
      } else {
        arrayEvents.forEach((eventFromArray) => {
          if (eventFromArray.id === eventInfos.id) {
            notificationService.consume(
              NOTIFICATION_MSG_TYPE.SUCCESS,
              "O evento foi adicionado ao seu carrinho. Acesse o carrinho para finalizar sua compra"
            );
            eventFromArray.quantity += 1;
            eventFromArray.price += price;
          }
        });
      }

      sessionStorage.setItem("eventsToBuy", JSON.stringify(arrayEvents));
    } else {
      notificationService.consume(
        NOTIFICATION_MSG_TYPE.ERROR,
        "Você precisar estar logado para comprar um evento."
      );
      setTimeout(() => window.location.assign("/sign-in"), 1500);
    }
  }

  function removeEventFromCart() {
    // Se a quantidade for igual a 0, remover do sessionStorage
    let arrayEvents = [];
    event.quantity -= 1;

    // let emailArrays = emails && emails.split(", ");

    if (sessionStorage.hasOwnProperty("eventsToBuy")) {
      arrayEvents = JSON.parse(sessionStorage.getItem("eventsToBuy"));
    }

    const index = arrayEvents.findIndex((ev) => ev.id === event.id);
    const found = arrayEvents.find((ev) => ev.id === event.id);

    if (!found) {
      return alert("Esse evento não está no seu carrinho!");
    }

    if (arrayEvents[index].quantity <= 1) {
      setQuantity(0);
      arrayEvents.splice(index, 1);
      setHasBuys(false);
      notificationService.consume(
        NOTIFICATION_MSG_TYPE.ERROR,
        "O evento foi removido do seu carrinho com sucesso."
      );
    } else {
      arrayEvents[index].quantity -= 1;
      setQuantity(arrayEvents[index].quantity);
      notificationService.consume(
        NOTIFICATION_MSG_TYPE.ERROR,
        "O ingresso foi removido do seu carrinho com sucesso."
      );
    }

    sessionStorage.setItem("eventsToBuy", JSON.stringify(arrayEvents));
  }

  /* <p>{event.areaDistributionImage}</p> */
  /* {event.musicalType} */

  return (
    <div className="event-detailed-widget pt-2 text-light w-100 h-100 d-flex align-items-center justify-content-center">
      {!event ? (
        <div className="text-center pt-5">Carregando evento...</div>
      ) : (
        <Col className="event-container w-100 p-0 pt-3">
          <div
            className="event-background-banner bg-light w-100"
            style={{ backgroundImage: `url('${event.bannerImage}')` }}
          ></div>
          <img
            src={event.bannerImage}
            alt="Banner do Evento"
            className="event-banner m-auto position-absolute"
          />

          {hasBuys && (
            <Button
              className="go-to-cart border-0 d-block ml-auto mt-5 mr-4"
              onClick={() => window.location.assign("/cart")}
            >
              Ir para o Carrinho
            </Button>
          )}

          <Col className="px-5 mt-5">
            <h1>{event.name}</h1>
            <Row className="mt-5">
              <Col className="text-left">
                <h4 className="d-flex align-items-center justify-content-start gap-2">
                  <MdDateRange /> Data e Horário
                </h4>
                <p>
                  Data: {formatDate(event.startDateTime)}{" "}
                  {formatDate(event.endDateTime) !==
                  formatDate(event.startDateTime)
                    ? `até ${formatDate(event.endDateTime)}`
                    : ""}{" "}
                  <br />
                  Horário: {formatTime(event.startDateTime)} -{" "}
                  {formatTime(event.endDateTime)}
                </p>
              </Col>
              <Col className="text-center">
                <h4 className="d-flex align-items-center justify-content-center gap-2">
                  <BiMap /> Local
                </h4>
                <p>{event.addressDescription}</p>
              </Col>
              <Col className="text-right">
                <h4 className="d-flex align-items-center justify-content-end gap-2">
                  <HiUserGroup /> Faixa Etária
                </h4>
                <p>{event.ageClassification} anos</p>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col>
                <p>{event.description}</p>
              </Col>
              <Col>
                <Accordion
                  defaultActiveKey="0"
                  alwaysOpen
                  className="bg-darkness border-none"
                >
                  <Accordion.Item
                    eventKey="0"
                    className="bg-darkness text-light"
                  >
                    <Accordion.Header>Ingressos Individuais</Accordion.Header>
                    {event.spaces.map((space) =>
                      space.allotments.length > 0 &&
                      space.allotments[0].ticketPriceUnisex > 0 ? (
                        <Accordion.Body>
                          <div className="d-flex justify-content-between align-items-center">
                            {space.name} - R$
                            {space.allotments[0].ticketPriceUnisex}
                            <div className="d-flex align-items-center justify-content-center">
                              {quantity > 0 && (
                                <IoMdRemoveCircle
                                  className="add-decrease-event"
                                  size={30}
                                  onClick={() => {
                                    setQuantity(quantity - 1);
                                    removeEventFromCart(space.id, "spaces");
                                  }}
                                />
                              )}
                              <p className="m-0 px-2">{quantity}</p>
                              <IoAddCircle
                                className="add-decrease-event"
                                size={30}
                                onClick={() => {
                                  setQuantity(quantity + 1);
                                  eventToCart(
                                    space.id,
                                    "spaces",
                                    space.allotments[0].ticketPriceUnisex
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Accordion.Body>
                      ) : space.allotments.length > 0 &&
                        space.allotments[0].ticketPriceMale > 0 ? (
                        <Accordion.Body>
                          <div className="d-flex justify-content-between align-items-center">
                            {space.name} Masculino - R$
                            {space.allotments[0].ticketPriceMale}
                            <div className="d-flex align-items-center justify-content-center">
                              {quantity > 0 && (
                                <IoMdRemoveCircle
                                  className="add-decrease-event"
                                  size={30}
                                  onClick={() => {
                                    setQuantity(quantity - 1);
                                    removeEventFromCart(space.id, "spaces");
                                  }}
                                />
                              )}
                              <p className="m-0 px-2">{quantity}</p>
                              <IoAddCircle
                                className="add-decrease-event"
                                size={30}
                                onClick={() => {
                                  setQuantity(quantity + 1);
                                  eventToCart(
                                    space.id,
                                    "spaces",
                                    space.allotments[0].ticketPriceMale,
                                    "MALE"
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            {space.name} Feminino - R$
                            {space.allotments[0].ticketPriceFemale}
                            <div className="d-flex align-items-center justify-content-center">
                              {quantity > 0 && (
                                <IoMdRemoveCircle
                                  className="add-decrease-event"
                                  size={30}
                                  onClick={() => {
                                    setQuantity(quantity - 1);
                                    removeEventFromCart(space.id, "spaces");
                                  }}
                                />
                              )}
                              <p className="m-0 px-2">{quantity}</p>
                              <IoAddCircle
                                className="add-decrease-event"
                                size={30}
                                onClick={() => {
                                  setQuantity(quantity + 1);
                                  eventToCart(
                                    space.id,
                                    "spaces",
                                    space.allotments[0].ticketPriceFemale,
                                    "FEMALE"
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Accordion.Body>
                      ) : (
                        <Accordion.Body>
                          {space.name} não está disponível no momento
                        </Accordion.Body>
                      )
                    )}
                  </Accordion.Item>

                  {event.cabins.length > 0 && (
                    <Accordion.Item
                      eventKey="1"
                      className="border-none bg-darkness text-light"
                    >
                      <Accordion.Header>Ingressos Grupais</Accordion.Header>
                      <Accordion.Body>
                        {event.cabins.map((cabin) => (
                          <div className="d-flex justify-content-between align-items-center">
                            {cabin.name} - R${cabin.unitPrice},00 (
                            {cabin.ticketQuantity} ingressos)
                            <div className="d-flex align-items-center justify-content-center">
                              {quantity > 0 && (
                                <IoMdRemoveCircle
                                  className="add-decrease-event"
                                  size={30}
                                  onClick={() => {
                                    setQuantity(quantity - 1);
                                    //eventToCart(space.id, "spaces")
                                  }}
                                />
                              )}
                              <p className="m-0 px-2">{quantity}</p>
                              <IoAddCircle
                                className="add-decrease-event"
                                size={30}
                                onClick={() => {
                                  setQuantity(quantity + 1);
                                  //eventToCart(cabin.id, "cabins")
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              </Col>
            </Row>
          </Col>
        </Col>
      )}
    </div>
  );
};
