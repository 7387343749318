import React from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { FooterWidget } from "../../widgets/footer/footer-widget";
import { HeaderWidget } from "../../widgets/header/header-widget";
import EventService from "../../../services/event-service";
import "./events-page.scss";

export default class EventsPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      events: [],
      nameFilter: "",
      dateFilter: "",
      cityFilter: "",
      typeInput: "text"
    };
  }

  componentDidMount() {
    let service = new EventService();
    let res = service.getAll();

    res.then((data) => {
      this.setState({
        ...this.state,
        events: data,
        isLoading: false,
      });
    });

    return;
  }

  async getEventsByFilter() {
    this.setState({
      ...this.state,
      isLoading: true,
    });

    let service = new EventService()
    let res = service.getByFilter(this.state.nameFilter, this.state.dateFilter, this.state.cityFilter);

    res.then((data) => {
      this.setState({
        ...this.state,
        events: data,
        isLoading: false,
      });
    });
  }

  render() {
    return (
      <div className="events-page bg-dark">
        <HeaderWidget />

        <h1 className="text-light my-4 text-center">🎫 Eventos</h1>

        <div className="content w-75 mx-auto mt-5">
          <div className="w-50 mx-auto mb-5">
            <InputGroup className="mb-2 search searchbar-events">
              <Form.Control
                size="lg"
                type="text"
                placeholder="Pesquisar por nome"
                value={this.state.searchbarValue}
                onChange={(e) => {
                  this.setState({
                    ...this.state,
                    nameFilter: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.code.toUpperCase() === "ENTER") {
                    this.getEventsByFilter();
                  }
                }}
              />
              <InputGroup.Text
                onClick={() => {
                  this.getEventsByFilter();
                }}
              >
                <i className="fas fa-search" />
              </InputGroup.Text>
            </InputGroup>

            <InputGroup className="mt-3">
              <Form.Control
                className="bg-dark text-light mr-3"
                type={this.state.typeInput}
                placeholder="Por Data"
                onClick={() => { this.setState({ ...this.state, typeInput: "date" }) }}
                onChange={(e) => this.setState({ ...this.state, dateFilter: e.target.value })}
              />

              <Form.Select
                className="bg-dark text-light"
                type="select"
                placeholder="Por Cidade"
                onChange={(e) => this.setState({ ...this.state, cityFilter: e.target.value })}
              >
                <option value="">Por cidade</option>
                <option value="Florianopolis">Florianópolis</option>
                <option value="Londrina">Londrina</option>
                <option value="Porto Alegre">Porto Alegre</option>
              </Form.Select>
            </InputGroup>

            <Button
              className="mt-2 w-100 filter-button"
              onClick={() => { this.getEventsByFilter() }}
            >
              Enviar!
            </Button>
          </div>

          {this.state.isLoading ? (
            <div className="text-light text-center">
              <p>Carregando eventos...</p>
            </div>
          ) : null}

          {!this.state.isLoading && this.state.events.length > 1 && (
            this.state.events.map((event, i) => (
              <div>
                {/* {event.name === "Welcome Dibba" && ( */}
                <Card className="bg-dark border border-secondary my-2 text-light">

                  <Card.Body>
                    <h4>{event.name}</h4>
                    <small className="text-secondary">{event.description}</small>

                    <div
                      className="d-flex justify-content-end"
                      style={{ gap: "16px" }}
                    >
                      <Button 
                        variant="outline-light" 
                        onClick={() => {window.location.assign(`/event/${event.id}`)}}
                      >Ver detalhes</Button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            )))
          }

          {!this.state.isLoading && this.state.events.length === 1 && (
            <div>
              <Card className="bg-dark border border-secondary my-2 text-light">
                <Card.Body>
                  <h4>{this.state.events[0].name}</h4>
                  <small className="text-secondary">{this.state.events[0].description}</small>

                  <div
                    className="d-flex justify-content-end"
                    style={{ gap: "16px" }}
                  >
                    <Button 
                      variant="outline-light"
                      onClick={() => {window.location.assign(`/event/${this.state.events[0].id}`)}}
                    >Ver detalhes</Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
          )
          }

          {!this.state.isLoading && this.state.events.length === 0 && (
            <div>
              <h5 className="text-light text-center">Desculpe, não encontramos nenhum evento com essas caracteristicas</h5>
            </div>
          )}
        </div>

        <FooterWidget />
      </div>
    );
  }
}