import "./home-page.scss";
import React from "react";
import { Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { HeaderWidget } from "../../widgets/header/header-widget";
import { FooterWidget } from "../../widgets/footer/footer-widget";
import { BasicSectionWidget } from "../../widgets/basic-section/basic-section-widget";
import appImage from "../../../assets/images/ticket-home.jpeg";
import appleButton from "../../../assets/images/apple-download.png"
import googleButton from "../../../assets/images/google-download.png"

import sliderDefaultImage from "../../../assets/images/party/03.jpg";

import EventService from "../../../services/event-service";
import { EventCardWidget } from "../../widgets/event-card/event-card-widget";
import { HomeSliderWidget } from "../../widgets/home-slider/home-slider-widget";

export default class HomePage extends React.Component {
  constructor() {
    super();
    this.state = {
      events: [],
      isLoading: true,
      sliderItems: [
        {
          title: "Seja bem-vindo à DIBBA",
          image: sliderDefaultImage,
        },
      ],
    };
  }
  componentDidMount() {
    const eventsService = new EventService();
    let events = eventsService.getAll();
    events.then((res) => {
      this.setState({
        ...this.state,
        events: res,
        isLoading: false,
      });
    });
    if (events.length > 1) {
      this.setState({
        ...this.state,
        sliderItems: [
          {
            title: "Seja bem-vindo à DIBBA",
            image: sliderDefaultImage,
          },
          this.state.events.map((event) => {
            return { title: event.name, image: event.bannerImage };
          }),
        ],
      });
    } else if (events.length === 1) {
      this.setState({
        ...this.state,
        sliderItems: [
          {
            title: "Seja bem-vindo à DIBBA",
            image: sliderDefaultImage,
          },
          {
            title: events[0].name,
            image: events[0].bannerImage,
          },
        ],
      });
    }
  }

  render() {
    return (
      <div className="home-page bg-dark text-light">
        <HeaderWidget />
        <HomeSliderWidget data={this.state.sliderItems} />

        <div className="home-content w-75 mx-auto my-4 pt-4">
          <div className="most-viewed mt-5">
            <BasicSectionWidget title={"🔥 Em alta"} />
            {this.state.isLoading ? (
              <p className="text-center">Carregando eventos...</p>
            ) : !this.state.events ? (
              <p className="text-center">Nenhum evento foi encontrado</p>
            ) : (
              <div className="card-list d-grid">
                <Row xl={3} md={2} xs={1}>
                  {this.state.events.map((ev, i) => (
                    <Col key={i} className="pt-3">
                      <EventCardWidget event={ev} />
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </div>
        </div>

        <Row className="home-download-section d-flex justify-content-between align-items-start pt-5 w-75 mx-auto">
          <Col className="content w-50 h-100 d-flex flex-column justify-content-between p-0">
            <Col className="motivate-text p-0">
              <h2>📱 Aproveite as melhores festas na palma da sua mão</h2>

              <p className="fw-normal">
                Baixe o app da Dibba no seu celular!! <br /> Disponível em
                Android e iOS, desta forma você terá muito mais facilidade para
                adquirir ingressos e acessar as festas que você for!!
              </p>
            </Col>

            <Col className="download-buttons p-0 d-flex justify-content-start gap-3 w-100">
              <a href="https://apps.apple.com/br/app/dibba/id6444484393?l=en" className="mt-auto w-50">
                <Image src={appleButton} className="w-100" />
              </a>
              <a href="#downloadGoogle" className="mt-auto w-50">
                <Image src={googleButton} className="w-100" />
              </a>
            </Col>
          </Col>

          <Col className="app-image w-50 h-100 d-flex justify-content-end p-0">
            <Image src={appImage} className="mobileMockup h-100" />
          </Col>
        </Row>

        <FooterWidget />
      </div>
    );
  }
}
